import {
  useMutation,
  UseMutationResult
} from '@tanstack/react-query'

import { CreateResourceItemParameters } from '@interfaces/api/definition/resource'
import useAxios from '@services/api/axios'

const useCreateEntry = ({
  headers,
  id,
  path
}: CreateResourceItemParameters): UseMutationResult => {
  const { axiosRequest } = useAxios()
  let url = `api/${path}`

  if (id) {
    url = url.replace('{uid}', id)
  }

  return useMutation({
    mutationFn: async (data) => await axiosRequest<never>({
      data,
      headers,
      method: 'POST',
      url
    })
  })
}

export default useCreateEntry
