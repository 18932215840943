import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { MediaFieldsInterfaces } from '@components/api-resource/api-resource-form/form-fields/media-fields'
import {
  FILE_FIELDS,
  IMAGE_FIELDS,
  IMAGE_MULTI_FIELDS,
  VIDEO_FIELDS
} from '@components/api-resource/api-resource-form/form-utils/fields'
import FormFileField from '@components/form-fields/form-file-field/form-file-field'
import FormImageField from '@components/form-fields/form-image-field'
import FormMultiImageField from '@components/form-fields/form-multi-image-field'

const MediaFields = ({
  definition,
  initialValues,
  isUploading,
  requestFields,
  setIsUploading
}: MediaFieldsInterfaces) => {
  const { t } = useTranslation('apiResources')

  const fields: ReactElement[] = []

  requestFields.forEach((field) => {
    if ('type' in field.properties) {
      if (IMAGE_FIELDS.includes(field.name)) {
        fields.push(
          <FormImageField
            key={field.name}
            label={t([`labels.${definition.name}.${field.name}`, `labels.${field.name}`])}
            name={field.name}
            required={field.required ?? false}
            value={initialValues[field.name]}
          />
        )
      } else if (IMAGE_MULTI_FIELDS.includes(field.name)) {
        fields.push(
          <FormMultiImageField
            key={field.name}
            label={t([`labels.${definition.name}.${field.name}`, `labels.${field.name}`])}
            name={field.name}
            required={field.required ?? false}
            value={initialValues[field.name]}
          />
        )
      } else if (FILE_FIELDS.includes(field.name)) {
        fields.push(
          <FormFileField
            accept={'.pdf'}
            isSuccess={false}
            key={field.name}
            label={t([`labels.${definition.name}.${field.name}`, `labels.${field.name}`])}
            name={field.name}
            required={true}
            translateKey='PDF'
          />
        )
      } else if (VIDEO_FIELDS.includes(field.name.toLowerCase()) && field.properties.format === 'iri-reference') {
        /* fields.push(
          <FormVideoField
            isUploading={isUploading}
            key={field.name}
            label={t([`labels.${definition.name}.${field.name}`, `labels.${field.name}`])}
            name={field.name}
            setIsUploading={setIsUploading}
          />
        ) */
      }
    }
  })

  return (
    <>
      {fields.length > 0 && (
        <div className='flex flex-col gap-3 border-t border-gray-900/10 mt-4 pt-4'>
          <h2 className='text-base font-semibold leading-7 text-gray-900'>{t('labels.medias')}</h2>

          <div className='flex flex-col space-y-4'>
            {fields.map((fieldElement, index) => (
              <div className='flex gap-4' key={index}>
                {fieldElement}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default MediaFields
