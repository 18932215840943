import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import {
  StatusFieldsInterfaces
} from '@components/api-resource/api-resource-form/form-fields/status-fields/status-fields.interfaces'
import FormSelectField from '@components/form-fields/form-select-field'
import { Status } from '@interfaces/api/status'
import { isPropertyType } from '@services/tools/api-resources/property-types'

const StatusFields = ({
  definition,
  requestFields
}: StatusFieldsInterfaces) => {
  const { t } = useTranslation('apiResources')
  const { t: status } = useTranslation('status')

  const fields: ReactElement[] = []

  requestFields.forEach((field, index) => {
    if ('type' in field.properties) {
      if (isPropertyType(field, 'string') && field.name === 'status') {
        const options = [
          {
            key: Status.STATUS_DRAFT,
            name: status(Status.STATUS_DRAFT)
          },
          {
            key: Status.STATUS_PUBLISHED,
            name: status(Status.STATUS_PUBLISHED)
          },
          {
            key: Status.STATUS_UNPUBLISHED,
            name: status(Status.STATUS_UNPUBLISHED)
          }
        ]

        fields.push(<FormSelectField key={field.name} label={t([`labels.${definition.name}.${field.name}`, `labels.${field.name}`])} name={field.name} options={options} size='small' />)
      }
    }
  })

  return (
    <>
      {fields}
    </>
  )
}

export default StatusFields
