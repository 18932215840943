import classNames from 'classnames'
import React from 'react'

import ButtonLoader from '@components/button-loader/button-loader'
import { ContextualButtonProps } from '@components/buttons/contextual-button/index'

const ContextualButton = ({
  children,
  disabled = false,
  icon: Icon,
  isLoading = false,
  onClick,
  style,
  type = 'button'
}: ContextualButtonProps) => {
  const buttonStyle = classNames(
    'flex gap-2 items-center rounded-lg px-3 py-1.5 text-sm font-semibold tracking-wide',
    {
      'border border-green-500 bg-green-500 text-white fill-green-500 hover:bg-white hover:text-green-500 hover:fill-white': style === 'success',
      'border border-red-500 bg-white text-red-500 fill-red-500 hover:border-red-500 hover:bg-red-500 hover:text-white hover:fill-white': style === 'warning',
      'border border-transparent bg-slate-800 text-white fill-white hover:bg-gray-600 hover:text-white': !disabled && !isLoading && style !== 'warning' && style !== 'success',
      'cursor-not-allowed bg-slate-400': disabled || isLoading,
      'cursor-pointer': !disabled && !isLoading
    }
  )

  return (
    <button
      className={buttonStyle}
      disabled={disabled || isLoading}
      onClick={onClick}
      type={type}
    >
      {children}

      {isLoading && <ButtonLoader dark />}

      {!isLoading && Icon && <Icon className='flex-none size-4' />}
    </button>
  )
}

export default ContextualButton
