import { useTranslation } from 'react-i18next'

import { StatusTagProps } from '@components/status-tag/status-tag-interfaces'
import Tag from '@components/tag/tag'
import { Status } from '@interfaces/api/status'

const StatusTag = ({ status }: StatusTagProps) => {
  const { t: translateStatus } = useTranslation('status')

  return (
    <Tag
      classNames={status === Status.STATUS_PUBLISHED ? 'bg-green-400' : status === Status.STATUS_FINISHED ? 'bg-green-600' : 'bg-secondary'}
      text={translateStatus(status) ?? '-'}
    />
  )
}

export default StatusTag
