import { Switch } from '@headlessui/react'
import {
  ErrorMessage,
  Field,
  useField,
  useFormikContext
} from 'formik'

import { FormToggleFieldProps } from '@components/form-fields/form-toggle-field/index'
import { captureException } from '@services/exceptions/capture-exception'

const FormToggleField = ({
  label,
  name,
  onChange,
  required = false
}: FormToggleFieldProps) => {
  const [input, ,] = useField({
    name
  })
  const { setFieldValue } = useFormikContext()

  const { value } = input

  return (
    <div className='flex items-center gap-3'>
      <div className='relative flex justify-center items-center'>
        <Switch
          checked={value}
          className={`${
            value ? 'bg-green-500' : 'bg-gray-200'
          } relative inline-flex h-6 w-11 items-center rounded-full`}
          onChange={(checked) => {
            if (onChange) {
              onChange(checked)
            }
            setFieldValue(name, checked).catch(captureException)
          }}
        >
          <span
            className={`${
              value ? 'translate-x-6' : 'translate-x-1'
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </Switch>

        <Field
          className='hidden'
          name={name}
          required={required}
          type='checkbox'
        />
      </div>

      <label className='block font-medium text-gray-500 text-sm' htmlFor={name}>{label}</label>

      <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name={name} />
    </div>
  )
}

export default FormToggleField
