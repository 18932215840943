import { CheckCircleIcon } from '@heroicons/react/24/outline'
import {
  DefaultError,
  UseMutateAsyncFunction,
  useQueryClient
} from '@tanstack/react-query'
import classNames from 'classnames'
import {
  useEffect,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'

import { ApiResourceMoveProps } from '@components/api-resource/api-resource-move/api-resource-move.interfaces'
import ContextualButton from '@components/buttons/contextual-button'
import Tree from '@root/components/tree'
import { MovedItem } from '@root/components/tree-item/tree-item-interfaces'
import { ApiPlatformEntity } from '@root/interfaces/api'
import { getApiResourceItemQueryUrl } from '@services/api/resources/item-query'
import useApiResourceListQuery from '@services/api/resources/list-query'
import usePatchEntry from '@services/api/resources/patch-entry-query'
import { captureException } from '@services/exceptions/capture-exception'

const ApiResourceMove = ({
  afterSubmit,
  collectionDefinition,
  handleMove,
  id,
  isModal,
  moveDefinition,
  onEdit
}: ApiResourceMoveProps) => {
  const [temporaryTree, setTemporaryTree] = useState<MovedItem[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { t } = useTranslation('api-resources', {
    keyPrefix: 'buttons'
  })

  const {
    data: {
      'hydra:member': resourceEntries = []
    } = {},
    isLoading,
    refetch
  } = useApiResourceListQuery({
    definition: collectionDefinition
  })

  useEffect(() => {
    if (resourceEntries?.length && !temporaryTree.length) {
      setTemporaryTree(resourceEntries as MovedItem[])
    }
  }, [resourceEntries])

  const [hasChanges, setHasChanges] = useState(false)
  const [tempPayload, setTempPayload] = useState<Record<string, unknown> | null>(null)

  const { mutateAsync: editResourceEntry } = usePatchEntry({
    id,
    path: moveDefinition.url
  })
  const queryKey = getApiResourceItemQueryUrl(moveDefinition.url, id)
  const queryClient = useQueryClient()

  const reload = async () => {
    await refetch()
  }

  const onSubmit: UseMutateAsyncFunction<unknown, DefaultError, unknown, unknown> = async () => {
    try {
      setIsSubmitting(true)

      const result = await editResourceEntry(tempPayload) as ApiPlatformEntity

      onEdit?.(result.uid)

      if (result) {
        await afterSubmit?.(result)
      }

      // Invalidate query after update to get fresh data
      queryClient.invalidateQueries({
        queryKey: [queryKey]
      }).catch(captureException)

      await reload()

      return result
    } catch (error) {
      captureException(error as Error)
    } finally {
      setHasChanges(false)
      setTempPayload(null)
      setIsSubmitting(false)
    }
  }

  const onMoveCompleted = (placement: string, referenceNode: string) => {
    handleMove?.(id, referenceNode, placement)
    setTempPayload(
      {
        position: {
          placement,
          referenceNode
        }
      }
    )

    setHasChanges(true)
  }

  return (
    <div className='p-4'>
      <Tree
        handleMove={onMoveCompleted}
        isLoading={isLoading}
        items={temporaryTree}
        movableItemId={id}
      />

      <div className={classNames(
        'w-full bottom-0 right-0 py-2 pr-4 flex items-end justify-end',
        {
          'fixed bg-white/10 backdrop-blur-lg': !isModal,
          'sticky bg-white/10 backdrop-blur-lg': isModal
        }
      )}
      >
        <ContextualButton
          disabled={isSubmitting || !hasChanges}
          icon={CheckCircleIcon}
          isLoading={isSubmitting}
          onClick={onSubmit}
        >
          {t('confirm')}

        </ContextualButton>
      </div>
    </div>
  )
}

export default ApiResourceMove
