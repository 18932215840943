import { Resource } from '@api-platform/api-doc-parser'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { OpenAPIV3_1 } from 'openapi-types'
import {
  useEffect,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'

import { SelectInputApiProps } from '@components/api-resource/api-resource-list-filters/SelectInputApi.interfaces'
import { Option } from '@components/form-fields/form-select-field'
import useApiResourceListQuery from '@services/api/resources/list-query'

const SelectInputApi = ({
  definitionName,
  entryLabelAttribute = 'name',
  filter,
  onChangeHandler
}: SelectInputApiProps) => {
  const selectRef = useRef<HTMLSelectElement>(null)
  const { t } = useTranslation('apiResources')
  const resource = new Resource(definitionName, definitionName)
  const [options, setOptions] = useState<Option[]>()
  const {
    data: { 'hydra:member': resourceEntries = [] } = {}
  } = useApiResourceListQuery({
    definition: resource,
    parameters: {
      pagination: false
    }
  })

  const getFilterLabel = (filter: OpenAPIV3_1.ParameterObject) => {
    return t([`filters.${filter.name}`, `filters.${definitionName}.${filter.name}`, `labels.${definitionName}.${filter.name}`, `labels.${filter.name}`])
  }

  useEffect(() => {
    if (!options?.length) {
      const entriesOptions = resourceEntries.map((resourceEntry) => {
        if (entryLabelAttribute in resourceEntry) {
          const option: Option = {
            key: resourceEntry.uid,
            name: resourceEntry[entryLabelAttribute] as string
          }

          return option
        }

        return {
          key: resourceEntry.uid,
          name: resourceEntry.uid
        }
      })

      if (JSON.stringify(entriesOptions) !== JSON.stringify(options)) {
        setOptions(entriesOptions)
      }
    }
  }, [resourceEntries])

  if (!resourceEntries || !options) {
    return <></>
  }

  return (
    <div className='flex flex-col' key={filter.name}>
      <div className='flex items-center shadow shadow-gray-200 rounded-lg relative bg-white'>
        <select
          className={`appearance-none block w-full py-2.5 min-w-[200px] pl-4 pr-10 rounded-lg focus:outline-none cursor-pointer text-sm ${selectRef?.current?.value ? 'text-gray-900' : 'text-gray-400'}`}
          id={filter.name}
          onChange={onChangeHandler(filter)}
          ref={selectRef}
        >
          <option value={''}>{`${t('filters.filterBy')} ${getFilterLabel(filter).toLowerCase()}`}</option>

          {options.map((entry) => (
            <option key={entry.key} value={entry.key}>{entry.name}</option>
          ))}
        </select>

        <ChevronDownIcon className='size-5 flex-none absolute right-4 pointer-events-none' />
      </div>
    </div>
  )
}

export default SelectInputApi
