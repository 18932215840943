import moment from 'moment'

import { DateProps } from '@components/api-resource/resource-entry-fields/date/index'

const DateField = ({
  className,
  datetimeEnabled = false,
  value
}: DateProps) => {
  return (
    <div
      className={`${className ?? 'inline-flex rounded-md text-gray-700 whitespace-nowrap'}`}
    >
      {datetimeEnabled ? moment(value).format('L LT') : moment(value).format('L')}
    </div>
  )
}

export default DateField
