import { ListBulletIcon } from '@heroicons/react/24/outline'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApiResource from '@components/api-resource'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import SectionTitle from '@components/section-title/section-title'
import { ApiPlatformEntity } from '@interfaces/api/api'
import { Brand } from '@interfaces/api/brand'
import ApiResourceMutateWrapperModal from '@root/components/api-resource-mutate-wrapper-modal'
import useApiResource from '@services/api/definition'
import { captureException } from '@services/exceptions/capture-exception'

const BrandsList = () => {
  const { t: translateResource } = useTranslation('apiResources', {
    keyPrefix: 'brands'
  })

  const breadcrumbs = [
    {
      href: '',
      name: translateResource('title')
    }
  ]

  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [brand, setBrand] = useState<Brand>()

  const queryClient = useQueryClient()
  const definition = useApiResource('brands/{uid}')
  const collectionDefinition = useApiResource('brands')

  const onReload = () => {
    queryClient.refetchQueries({
      queryKey: ['brands']
    }).catch(captureException)
  }

  const onCreateClick = () => {
    setOpen(true)
  }

  const onEditClick = (entry) => {
    setBrand(entry)
    setOpenEdit(true)
  }

  const onEdit = () => {
    onReload()
    setOpenEdit(false)
    setOpen(false)
  }

  const approveBrandPatch = (entry: ApiPlatformEntity) => ({
    ...(entry as Brand),
    validating: false,
    visible: true
  })

  return (
    <Layout description={translateResource('description')} title={translateResource('title')}>
      <Guard>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle icon={ListBulletIcon} title={translateResource('title')} />

        <ApiResource
          name={'brands'}
          withApproveOption={approveBrandPatch}
          withCreateOption={onCreateClick}
          withDeleteOption
          withEditOption={onEditClick}
        />

        <ApiResourceMutateWrapperModal
          createDefinition={collectionDefinition}
          createTitle={translateResource('create')}
          createVisible={open}
          editDefinition={definition}
          editTitle={translateResource('edit')}
          editVisible={openEdit}
          onSave={onEdit}
          resource={brand}
          setCreateVisible={setOpen}
          setEditVisible={setOpenEdit}
        />
      </Guard>
    </Layout>
  )
}

export default BrandsList
