import classNames from 'classnames'
import {
  ErrorMessage,
  Field
} from 'formik'

import { FormTextFieldProps } from '@components/form-fields/form-text-field/index'

const FormTextField = ({
  label,
  name,
  placeholder,
  required = false,
  type = 'text',
  uppercase = false
}: FormTextFieldProps) => {
  return (
    <div className='flex flex-col w-full'>
      <label className='block font-medium text-gray-900 text-sm' htmlFor={name}>
        {label}

        {required && <sup className='text-gray-500 font-normal'>*</sup>}
      </label>

      <Field
        className={classNames(
          'appearance-none block w-full mt-2 px-3 py-2 border border-gray-300 bg-gray-50 rounded-lg placeholder-gray-500 focus:outline-none',
          {
            uppercase
          }
        )}
        name={name}
        placeholder={placeholder}
        required={required}
        type={type}
      />

      <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name={name} />
    </div>
  )
}

export default FormTextField
