interface SearchMapField {
  path: string
  searchField: string
}
const SearchMap: Record<string, SearchMapField> = {
  category: {
    path: 'categories',
    searchField: 'name'
  },
  position: {
    path: 'categories',
    searchField: 'name'
  }
}

export default SearchMap
