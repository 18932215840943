import {
  useEffect,
  useState
} from 'react'

import SkeletonLoader from '@root/components/skeleton-loader/skeleton-loader'
import { TreeProps } from '@root/components/tree/tree.interfaces'
import TreeItem from '@root/components/tree-item'
import { MovedItem } from '@root/components/tree-item/tree-item-interfaces'

const Tree = ({
  handleMove,
  isLoading,
  items,
  movableItemId: id
}: TreeProps) => {
  const [temporaryTree, setTemporaryTree] = useState<MovedItem[]>([])
  const [movedItemId, setMovedItemId] = useState<string | null>(null)

  useEffect(() => {
    console.log('resourceEntries', items)
    if (items?.length && !temporaryTree.length) {
      setTemporaryTree(items)
    }
  }, [items])

  const handleLocalMove = (draggedId: string, reference: string, placement: string, referenceNode: string) => {
    setMovedItemId(draggedId)
    handleMove?.(placement, referenceNode)

    setTemporaryTree(prevTree => {
      const newTree = JSON.parse(JSON.stringify(prevTree)) as MovedItem[]

      const removeExistingClones = (items: MovedItem[]) => {
        if (!Array.isArray(items)) {
          return
        }

        for (let i = items.length - 1; i >= 0; i--) {
          if (items[i]?.uid === draggedId && items[i]?.isCloned) {
            items.splice(i, 1)
          }
          if (items[i]?.children && Array.isArray(items[i].children)) {
            removeExistingClones(items[i].children as MovedItem[])
          }
        }
      }

      const findItemToClone = (items: MovedItem[]): MovedItem | null => {
        if (!Array.isArray(items)) {
          return null
        }

        for (let i = 0; i < items.length; i++) {
          if (items[i]?.uid === draggedId && !items[i]?.isCloned) {
            return items[i]
          }
          if (items[i]?.children && Array.isArray(items[i].children)) {
            const found = findItemToClone(items[i].children as MovedItem[])
            if (found) {
              return found
            }
          }
        }

        return null
      }

      const insertClonedItem = (items: MovedItem[], clone: MovedItem): boolean => {
        if (!Array.isArray(items)) {
          return false
        }

        for (let i = 0; i < items.length; i++) {
          if (items[i]?.uid === reference) {
            const clonedItem = {
              ...clone,
              isCloned: true
            }

            switch (placement) {
              case 'prev_sibling':
                items.splice(i, 0, clonedItem)

                return true
              case 'next_sibling':
                items.splice(i + 1, 0, clonedItem)

                return true
              case 'first_child':
                if (!items[i].children) {
                  items[i].children = []
                }
                items[i].children?.unshift(clonedItem)

                return true
            }
          }
          if (items[i]?.children && Array.isArray(items[i].children)) {
            if (insertClonedItem(items[i].children as MovedItem[], clone)) {
              return true
            }
          }
        }

        return false
      }

      removeExistingClones(newTree)

      // Then add the new clone
      const itemToClone = findItemToClone(newTree)
      if (itemToClone) {
        insertClonedItem(newTree, itemToClone)
      }

      return newTree
    })
  }

  if (isLoading) {
    return <SkeletonLoader type='list' />
  }

  return (
    <>
      {temporaryTree?.map((item, index) => (
        <TreeItem
          id={id}
          item={item}
          key={`${item.uid}-${index}`}
          level={0}
          movedItemId={movedItemId}
          onMove={handleLocalMove}
          referenceNodeField='@id'
          siblings={items}
        />
      ))}
    </>
  )
}

export default Tree
