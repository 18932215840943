/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { ReactElement } from 'react'

import { RadioFieldsInterfaces } from '@components/api-resource/api-resource-form/form-fields/radio-fields'
import { fieldIsHidden } from '@components/api-resource/api-resource-form/form-utils/fields'
import { isPropertyType } from '@services/tools/api-resources/property-types'

const RadioFields = ({
  definition,
  fieldsToDisplay,
  fieldsToHide,
  requestFields
}: RadioFieldsInterfaces) => {
  // const { t } = useTranslation('apiResources')

  const fields: ReactElement[] = []

  // Créez un objet pour associer les noms de champs aux composants correspondants
  const fieldComponents = {}

  requestFields.forEach((field, index) => {
    if ('type' in field.properties) {
      if (
        fieldIsHidden(field, fieldsToDisplay, fieldsToHide) ||
        isPropertyType(field, 'integer') ||
        field.properties.enum ||
        (
          field.name !== 'title' &&
          field.name !== 'name')
      ) {
        // return
      }

      /** if (field.name === 'type' && definition.name === 'sections') {
        const sectionTypeOptions: RadioOption[] = Object.keys(SectionType).map((key) => ({
          image: `/assets/sections/${SectionType[key]}.png`,
          key,
          name: t(`sections.types.${SectionType[key]}`)
        }))
        fieldComponents[field.name] = <FormikRadioGroup key={field.name} label={t([`labels.${definition.name}.${field.name}`, `labels.${field.name}`])} name={field.name} options={sectionTypeOptions} />
      }**/
    }
  })

  // Parcourez fieldsToDisplay si défini, sinon ajoutez les champs dans l'ordre d'origine
  if (fieldsToDisplay) {
    fieldsToDisplay.forEach((fieldName) => {
      if (fieldComponents[fieldName]) {
        fields.push(fieldComponents[fieldName])
      }
    })
  } else {
    Object.keys(fieldComponents).forEach((fieldName) => {
      fields.push(fieldComponents[fieldName])
    })
  }

  return (
    <>
      {fields}
    </>
  )
}

export default RadioFields
