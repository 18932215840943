import { ListBulletIcon } from '@heroicons/react/24/outline'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApiResource from '@components/api-resource'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import SectionTitle from '@components/section-title/section-title'
import { Advert } from '@interfaces/api/advert'
import ApiResourceMutateWrapperModal from '@root/components/api-resource-mutate-wrapper-modal'
import useApiResource from '@services/api/definition'
import { captureException } from '@services/exceptions/capture-exception'

const AdvertsList = () => {
  const { t: translateResource } = useTranslation('apiResources', {
    keyPrefix: 'adverts'
  })

  const definition = useApiResource('adverts/{uid}')
  const [openEdit, setOpenEdit] = useState(false)
  const [advert, setAdvert] = useState<Advert>()

  const queryClient = useQueryClient()

  const onReload = () => {
    queryClient.refetchQueries({
      queryKey: ['adverts']
    }).catch(captureException)
  }

  const onEdit = () => {
    onReload()
    setOpenEdit(false)
  }

  const breadcrumbs = [
    {
      href: '',
      name: translateResource('title')
    }
  ]

  const onEditClick = (entry) => {
    setAdvert(entry)
    setOpenEdit(true)
  }

  return (
    <Layout description={translateResource('description')} title={translateResource('title')}>
      <Guard>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle icon={ListBulletIcon} title={translateResource('title')} />

        <ApiResource
          customFieldsToDisplay={['title', 'createdAt']}
          customFilters={['state', 'category', 'brand']}
          name={'adverts'}
          withApproveOption
          withDeleteOption
          withEditOption={onEditClick}
          withRejectOption
        />

        <ApiResourceMutateWrapperModal
          editDefinition={definition}
          editTitle={translateResource('edit')}
          editVisible={openEdit}
          onSave={onEdit}
          resource={advert}
          setEditVisible={setOpenEdit}
        />
      </Guard>
    </Layout>
  )
}

export default AdvertsList
