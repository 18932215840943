import {
  ApiPlatformEntity,
  ApiPlatformListResponse,
  FormattedApiListResponse
} from '@interfaces/api/api'

export const formatApiListResponse = <T extends ApiPlatformEntity>(
  response: ApiPlatformListResponse<T>
): FormattedApiListResponse<T> => ({
  data: response['hydra:member'] ?? [],
  id: response['@id'],
  pagination: response['hydra:view'],
  search: response['hydra:search'],
  totalItems: response['hydra:totalItems'] ?? 0
})
