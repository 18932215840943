import classNames from 'classnames'

import { MovedItem } from '@components/tree-item/tree-item-interfaces'

export enum TreeDragState {
  AFTER = 'after',
  BEFORE = 'before',
  INSIDE = 'inside'
}

export const useTreeItemClasses = (props: {
  dragState: TreeDragState | null
  id: string
  isDraggable: boolean
  isOver: boolean
  isParent: boolean
  item: MovedItem
  movedItemId: string | null
}) => {
  const {
    dragState,
    id,
    isDraggable,
    isOver,
    isParent,
    item,
    movedItemId
  } = props

  return classNames(
    // Base classes
    'flex items-center p-2 border border-transparent',
    'transition-all duration-200 ease-in-out',
    'hover:bg-gray-50',

    // Dynamic classes
    {
      'bg-blue-200 border-2 border-blue-500': dragState === 'inside',
      'bg-gray-50': isOver,
      'bg-green-200': item.uid === movedItemId && item.isCloned,
      'bg-red-200 opacity-70': item.uid === movedItemId && !item.isCloned,
      'border-b-2 border-blue-500': dragState === 'after',
      'border-t-2 border-blue-500': dragState === 'before',
      'cursor-move': isDraggable,
      'font-bold': isParent || id === item.uid
    }
  )
}
