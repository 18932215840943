import {
  ChevronDownIcon,
  ChevronUpIcon
} from '@heroicons/react/24/outline'

import { InputCyclingSelectProps } from '@components/input-cycling-select'

const InputCyclingSelect = ({
  currentValue,
  onChange,
  values
}: InputCyclingSelectProps) => {
  const onClick = () => {
    let newValue
    const currentIndex = values.findIndex(value => value.key === currentValue.key)
    const nextIndex = currentIndex + 1
    if (nextIndex > values.length - 1) {
      newValue = 0
    } else {
      newValue = nextIndex
    }
    onChange(values[newValue].key)
  }

  return (
    <button
      className='rounded text-xs'
      onClick={onClick}
    >
      {currentValue.label === 'Desc.'
        ? <ChevronDownIcon className='w-6 h-6' />
        : <ChevronUpIcon className='w-6 h-6' />
      }
    </button>
  )
}

export default InputCyclingSelect
