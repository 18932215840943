import { useMutation } from '@tanstack/react-query'

import { TransitionResourceItemParameters } from '@interfaces/api/definition/resource'
import useAxios from '@services/api/axios'

const useApproveEntry = () => {
  const { patch } = useAxios()

  return useMutation({
    mutationFn: async ({
      complete,
      id,
      path
    }: TransitionResourceItemParameters) => {
      const url = `api/${path.replace('{uid}', id)}${complete ? '/approve' : ''}`

      await patch(url, {}).then((body) => body)
    }
  })
}

export default useApproveEntry
