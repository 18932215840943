import { ListBulletIcon } from '@heroicons/react/24/outline'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApiResource from '@components/api-resource'
import ApiResourceMutateWrapperModal from '@components/api-resource-mutate-wrapper-modal'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import SectionTitle from '@components/section-title/section-title'
import { KycDocument } from '@interfaces/api/document'
import useApiResource from '@services/api/definition'
import { captureException } from '@services/exceptions/capture-exception'

const KycDocumentsList = () => {
  const { t: translateResource } = useTranslation('apiResources', {
    keyPrefix: 'kycDocuments'
  })

  const definition = useApiResource('kyc-documents/{uid}/preprocess/refuse')
  const [openEdit, setOpenEdit] = useState(false)
  const [kycDocument, setKycDocument] = useState<KycDocument>()

  const queryClient = useQueryClient()

  const onReload = () => {
    queryClient.refetchQueries({
      queryKey: ['kyc-documents']
    }).catch(captureException)
  }

  const onEdit = () => {
    onReload()
    setOpenEdit(false)
  }
  const onRefuseClick = (entry) => {
    setKycDocument(entry)
    setOpenEdit(true)
  }

  const breadcrumbs = [
    {
      href: '',
      name: translateResource('title')
    }
  ]

  return (
    <Layout description={translateResource('description')} title={translateResource('title')}>
      <Guard>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle icon={ListBulletIcon} title={translateResource('title')} />

        <ApiResource
          customFieldsToDisplay={['mangopayId', 'status', 'refusedReasonMessage', 'createdAt']}
          customFilters={['status', 'mangopayId']}
          name={'kyc-documents'}
          transitionAttribute={{
            approve: 'preprocess/approve'
          }}
          withApproveOption
          withDeleteOption
          withRejectOption={onRefuseClick}
        />

        <ApiResourceMutateWrapperModal
          editDefinition={definition}
          editTitle={translateResource('edit')}
          editVisible={openEdit}
          onSave={onEdit}
          resource={kycDocument}
          setEditVisible={setOpenEdit}
        />
      </Guard>
    </Layout>
  )
}

export default KycDocumentsList
