import {
  useEffect,
  useState
} from 'react'

import {
  MovedItem,
  TreeItemProps
} from '@components/tree-item/tree-item-interfaces'
import {
  TreeDragState,
  useTreeItemClasses
} from '@services/hooks/styling/tree'

const TreeItem = ({
  id,
  item,
  level,
  movedItemId,
  onMove,
  parent,
  previousParentId,
  referenceNodeField = 'uid',
  siblings = []
}: TreeItemProps) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [dragState, setDragState] = useState<TreeDragState | null>(null)
  const [isOver, setIsOver] = useState(false)

  const hasChildren = item.children && item.children.length > 0

  const isDraggable = id === item.uid
  const handleDragStart = (e: React.DragEvent) => {
    e.stopPropagation()
    e.dataTransfer.setData('text/plain', item.uid)
  }

  useEffect(() => {
    const shouldExpand =
      isParentOfSelectedItem(item, id) ||
      (dragState === 'inside' && isOver)

    if (shouldExpand) {
      setIsExpanded(true)
    }
  }, [id, item, dragState, isOver])

  const isParentOfSelectedItem = (item: MovedItem, selectedId: string): boolean => {
    if (!item.children) {
      return false
    }

    return item.children.some(child =>
      child.uid === selectedId || isParentOfSelectedItem(child, selectedId)
    )
  }

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setIsOver(true)

    const rect = e.currentTarget.getBoundingClientRect()
    const mouseY = e.clientY - rect.top
    const thirdHeight = rect.height / 3

    if (mouseY < thirdHeight) {
      setDragState(TreeDragState.BEFORE)
    } else if (mouseY > thirdHeight * 2) {
      setDragState(TreeDragState.AFTER)
    } else {
      setDragState(TreeDragState.INSIDE)
      setIsExpanded(true)
    }
  }

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setIsOver(false)
    setDragState(null)
  }

  const itemClasses = useTreeItemClasses({
    dragState,
    id,
    isDraggable,
    isOver,
    isParent: isParentOfSelectedItem(item, id),
    item,
    movedItemId
  })

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setIsOver(false)

    const draggedId = e.dataTransfer.getData('text/plain')
    if (draggedId !== item.uid) {
      if (dragState === TreeDragState.INSIDE) {
        setIsExpanded(true)
      }

      const rect = e.currentTarget.getBoundingClientRect()
      const mouseY = e.clientY - rect.top
      const thirdHeight = rect.height / 3

      // Find current item's index in siblings array
      const currentIndex = siblings.findIndex(sibling => sibling.uid === item.uid)
      const previousSibling = currentIndex > 0 ? siblings[currentIndex - 1] : null

      let referenceNode: string
      let referenceNodeValue: string
      let placement: string

      if (mouseY < thirdHeight) {
        // Top third - place as previous sibling
        if (previousSibling) {
          referenceNodeValue = previousSibling[referenceNodeField]
          referenceNode = previousSibling.uid
          placement = 'next_sibling'
        } else if (parent) {
          referenceNodeValue = parent[referenceNodeField]
          referenceNode = parent.uid
          placement = 'first_child'
        } else {
          referenceNodeValue = item[referenceNodeField]
          referenceNode = item.uid
          placement = 'prev_sibling'
        }
      } else if (mouseY > thirdHeight * 2) {
        // Bottom third - if expanded, place as first child, otherwise as next sibling
        if (isExpanded && hasChildren) {
          referenceNodeValue = item[referenceNodeField]
          referenceNode = item.uid
          placement = 'first_child'
        } else {
          referenceNodeValue = item[referenceNodeField]
          referenceNode = item.uid
          placement = 'next_sibling'
        }
      } else {
        // Middle third - place as first child
        referenceNodeValue = item[referenceNodeField]
        referenceNode = item.uid
        placement = 'first_child'
      }

      onMove(draggedId, referenceNode, placement, referenceNodeValue)
    }
  }

  const toggleExpand = (e: React.MouseEvent) => {
    e.stopPropagation()
    setIsExpanded(!isExpanded)
  }

  return (
    <div
      // className='tree-item'
      className='transition-all duration-200 ease-in-out'
      style={{
        marginLeft: `${level * 20}px`
      }}
    >
      <div
        className={itemClasses}
        draggable={isDraggable}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDragStart={handleDragStart}
        onDrop={handleDrop}
      >
        {hasChildren && (
          <button
            className='mr-2 w-6 h-6 flex items-center justify-center focus:outline-none'
            onClick={toggleExpand}
          >
            {isExpanded ? '▼' : '▶'}
          </button>
        )}

        {!hasChildren && <div className='w-6' />}

        <span className='select-none'>{item.name}</span>
      </div>

      {isExpanded && hasChildren && (
        <div className='children'>
          {item.children?.map((child, index) => (
            <TreeItem
              id={id}
              item={child}
              key={`${child.uid}-${index}`}
              level={level + 1}
              movedItemId={movedItemId}
              onMove={onMove}
              parent={item}
              previousParentId={previousParentId}
              referenceNodeField={referenceNodeField}
              siblings={item.children}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default TreeItem
