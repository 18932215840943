import ApiResourceCreate from '@components/api-resource/api-resource-create'
import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import ApiResourceMove from '@components/api-resource/api-resource-move'
import {
  ApiResourceMutateWrapperModalProps
} from '@components/api-resource-mutate-wrapper-modal/api-resource-mutate-wrapper-modal.interfaces'
import Modal from '@components/modals/modal'

const ApiResourceMutateWrapperModal = ({
  createDefinition,
  createTitle = '',
  createVisible,
  editDefinition,
  editTitle = '',
  editVisible,
  handleMove,
  moveCollectionDefinition,
  moveDefinition,
  moveTitle = '',
  moveVisible,
  onSave,
  resource,
  setCreateVisible,
  setEditVisible,
  setMoveVisible
}: ApiResourceMutateWrapperModalProps) => {
  const onDoneEditing = () => {
    setEditVisible?.(false)
    onSave?.()
  }

  const onDoneCreating = () => {
    setCreateVisible?.(false)
    onSave?.()
  }

  const onDoneMoving = () => {
    setMoveVisible?.(false)
    onSave?.()
  }

  return (
    <>
      {resource && setEditVisible && (
        <Modal center open={!!editVisible} setOpen={setEditVisible} size='large' title={editTitle}>
          {editDefinition && (
            <ApiResourceEdit definition={editDefinition} id={resource.uid} isModal onEdit={onDoneEditing} />
          )}
        </Modal>
      )}

      {setCreateVisible && (
        <Modal
          center
          open={!!createVisible}
          setOpen={setCreateVisible}
          size='large'
          title={createTitle}
        >
          {createDefinition &&
            <ApiResourceCreate definition={createDefinition} isModal onEdit={onDoneCreating} />
          }
        </Modal>
      )}

      {resource && setMoveVisible && (
        <Modal center open={!!moveVisible} setOpen={setMoveVisible} size='large' title={moveTitle}>
          {moveDefinition && moveCollectionDefinition && (
            <ApiResourceMove
              collectionDefinition={moveCollectionDefinition}
              handleMove={handleMove}
              id={resource?.uid}
              isModal
              moveDefinition={moveDefinition}
              onEdit={onDoneMoving}
            />
          )}
        </Modal>
      )}
    </>
  )
}

export default ApiResourceMutateWrapperModal
