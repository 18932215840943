import { Resource } from '@api-platform/api-doc-parser'
import {
  useQuery,
  UseQueryResult
} from '@tanstack/react-query'

import {
  ApiPlatformEntity,
  ApiPlatformListResponse
} from '@interfaces/api/api'
import useAxios from '@services/api/axios'

interface ResourceListParameters {
  definition: Resource
  parameters?: Record<string, boolean | number | string> | URLSearchParams
  uid?: string
}

const useApiResourceListQuery = <T extends ApiPlatformEntity>({
  definition,
  parameters,
  uid
}: ResourceListParameters): UseQueryResult<ApiPlatformListResponse<T>> => {
  const { get } = useAxios()

  const url = `api/${definition.url ? definition.url : definition.name}`

  const queryKey = [definition.name, parameters]
  const queryFn = async () => {
    return await get(url, parameters)
  }
  const staleTime = 5 * 60 * 1000

  return useQuery({
    queryFn,
    queryKey,
    staleTime
  })
}

export default useApiResourceListQuery
