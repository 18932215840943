import { ApiResourceProps } from '@components/api-resource'
import ApiResourceList from '@components/api-resource/api-resource-list'
import useApiResource from '@services/api/definition'

const ApiResource = ({
  createButton,
  createdAt,
  customFieldsToDisplay,
  customFilters,
  customParams,
  entryComponent,
  hasMargin,
  hideFilters = false,
  identifierAttribute = 'uid',
  name,
  onClick,
  transitionAttribute,
  uid,
  withApproveOption,
  withCloneOption = false,
  withCopyOption,
  withCreateOption,
  withDeleteOption = false,
  withEditOption,
  withMoveOption,
  withRejectOption
}: ApiResourceProps) => {
  const resource = useApiResource(name)
  const onClickHandler = (entry) => {
    if (onClick) {
      onClick(entry)
    }
  }

  return (
    <>
      {resource && (
        <ApiResourceList
          createButton={createButton}
          createdAt={createdAt}
          customFieldsToDisplay={customFieldsToDisplay}
          customFilters={customFilters}
          customParams={customParams}
          definition={resource}
          entryComponent={entryComponent}
          hasMargin={hasMargin}
          hideFilters={hideFilters}
          identifierAttribute={identifierAttribute}
          onEntryClick={onClickHandler}
          transitionAttribute={transitionAttribute}
          uid={uid}
          withApproveOption={withApproveOption}
          withCloneOption={withCloneOption}
          withCopyOption={withCopyOption}
          withCreateOption={withCreateOption}
          withDeleteOption={withDeleteOption}
          withEditOption={withEditOption}
          withMoveOption={withMoveOption}
          withRejectOption={withRejectOption}
        />
      )}
    </>
  )
}

export default ApiResource
