import { useMemo } from 'react'

import { ApiPlatformEntity } from '@interfaces/api'
import { Advert } from '@root/interfaces/api/advert'
import { Brand } from '@root/interfaces/api/brand'
import {
  KycDocument,
  KycDocumentStatus
} from '@root/interfaces/api/document'

export const useResourceActions = <T extends ApiPlatformEntity>(entry: T) => {
  const canApplyTransition = useMemo(() => {
    switch (entry['@type']) {
      case 'Brand':{
        const brand = entry as unknown as Brand

        return !!brand.validating
      }
      case 'Advert': {
        const advert = entry as unknown as Advert

        return advert.state === 'awaiting_approval'
      }
      case 'KycDocument': {
        const kycDocument = entry as unknown as KycDocument

        return kycDocument.status === KycDocumentStatus.created
      }
      default:
        return false
    }
  }, [entry])

  return {
    canApplyTransition
  }
}
