/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import {
  SelectFieldsInterfaces
} from '@components/api-resource/api-resource-form/form-fields/select-fields/select-fields.interfaces'
import { fieldIsHidden } from '@components/api-resource/api-resource-form/form-utils/fields'
import FormSelectField from '@components/form-fields/form-select-field'
import { ApiPlatformEntity } from '@interfaces/api'
import { KycDocumentAdminRefusedReasonType } from '@interfaces/api/document'
import { Lang } from '@interfaces/api/lang'
import { UserDisabledReasons } from '@interfaces/api/user/disabled-reasons'
import countriesJson from '@services/translations/fr/countries.json'

const SelectFields = <T extends ApiPlatformEntity>({
  data,
  definition,
  fieldsToDisplay,
  fieldsToHide,
  formValues,
  requestFields
}: SelectFieldsInterfaces<T>) => {
  const { t } = useTranslation('apiResources')

  const fields: ReactElement[] = []

  // Créez un objet pour associer les noms de champs aux composants correspondants
  const fieldComponents = {}

  requestFields.forEach((field, index) => {
    if ('type' in field.properties) {
      if (
        fieldIsHidden(field, fieldsToDisplay, fieldsToHide)
      ) {
        return
      }

      if (field.name === 'lang' && definition.name === 'users') {
        const options = [
          ...Object.values(Lang).map(unit => ({
            key: unit,
            name: t(`lang.${unit}`)
          }))
        ]

        fieldComponents[field.name] = <FormSelectField defaultValue={data?.[field.name] || Lang.en} key={field.name} label={t('labels.lang')} name={field.name} options={options} />
      } else if (field.name === 'disabledReason' && definition.name === 'users') {
        const options = [
          {
            key: '',
            name: t('users.disabled-reasons.default')
          },
          ...Object.values(UserDisabledReasons).map(unit => ({
            key: unit,
            name: t(`users.disabled-reasons.${unit}`)
          }))
        ]

        fieldComponents[field.name] = <FormSelectField defaultValue={data?.[field.name] || ''} key={field.name} label={t('labels.disabledReason')} name={field.name} options={options} />
      } else if (field.name === 'country' || field.name === 'nationality') {
        const sectionTypeOptions = Object.entries(countriesJson).map(([key, name]) => ({
          key,
          name
        }))
        fieldComponents[field.name] = <FormSelectField defaultValue={data?.[field.name]} key={field.name} label={t([`labels.${definition.name}.${field.name}`, `labels.${field.name}`])} name={field.name} options={sectionTypeOptions} />
      } else if (field.name === 'refusedReasonType') {
        const options = [
          {
            key: '',
            name: t('users.refused-reason.default')
          },
          ...Object.values(KycDocumentAdminRefusedReasonType).map(unit => ({
            key: unit,
            name: t(`users.refused-reason.${unit}`)
          }))
        ]

        fieldComponents[field.name] = <FormSelectField defaultValue={data?.[field.name] || ''} key={field.name} label={t('labels.disabledReason')} name={field.name} options={options} />
      }
    }
  })

  // Parcourez fieldsToDisplay si défini, sinon ajoutez les champs dans l'ordre d'origine
  if (fieldsToDisplay) {
    fieldsToDisplay.forEach((fieldName) => {
      if (fieldComponents[fieldName]) {
        fields.push(fieldComponents[fieldName])
      }
    })
  } else {
    Object.keys(fieldComponents).forEach((fieldName) => {
      fields.push(fieldComponents[fieldName])
    })
  }

  return (
    <>
      {fields}
    </>
  )
}

export default SelectFields
