import {
  ChevronDownIcon,
  MagnifyingGlassIcon
} from '@heroicons/react/24/outline'
import { OpenAPIV3_1 } from 'openapi-types'
import {
  ChangeEvent,
  ReactElement,
  useRef
} from 'react'
import { useTranslation } from 'react-i18next'

import SelectInputApi from '@components/api-resource/api-resource-list-filters/SelectInputApi'
import { ApiResourceListFiltersProps } from '@components/api-resource/api-resource-list-filters/index'

const ApiResourceListFilters = ({
  definitionName,
  filters,
  searchParams,
  setSearchParams
}: ApiResourceListFiltersProps) => {
  const selectRef = useRef<HTMLSelectElement>(null)
  const { t } = useTranslation('apiResources')

  const getFilterLabel = (filter: OpenAPIV3_1.ParameterObject) => {
    return t([`filters.${filter.name}`, `filters.${definitionName}.${filter.name}`, `labels.${definitionName}.${filter.name}`, `labels.${filter.name}`])
  }

  const onChangeHandler = (filter: OpenAPIV3_1.ParameterObject) => (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (event.target.value === '') {
      searchParams.delete(filter.name)
      setSearchParams(searchParams)
    } else {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        [filter.name]: event.target.value
      })
    }
  }

  const renderText = (filter) => (
    <div className='flex items-center shadow shadow-gray-200 rounded-lg px-4 bg-white' key={filter.name}>
      <MagnifyingGlassIcon className='size-5 text-gray-400' />

      <input
        className='appearance-none block w-full pl-3 py-2.5 text-sm placeholder-gray-400 placeholder:text-md focus:outline-none'
        id={filter.name}
        onChange={onChangeHandler(filter)}
        placeholder={`${t('filters.search')} ${getFilterLabel(filter).toLowerCase()}`}
        type='text'
      />
    </div>
  )

  const renderSelect = (filter, options) => (
    <div className='flex flex-col' key={filter.name}>
      <div className='flex items-center shadow shadow-gray-200 rounded-lg relative bg-white'>
        <select
          className={`appearance-none block w-full py-2.5 min-w-[200px] pl-4 pr-10 rounded-lg focus:outline-none cursor-pointer text-sm ${selectRef?.current?.value ? 'text-gray-900' : 'text-gray-400'}`}
          id={filter.name}
          onChange={onChangeHandler(filter)}
          ref={selectRef}
        >
          <option value={''}>{`${t('filters.filterBy')} ${getFilterLabel(filter).toLowerCase()}`}</option>

          {options.map((entry) => (
            <option key={entry.key} value={entry.key}>{t(`filters.${entry.name}`)}</option>
          ))}
        </select>

        <ChevronDownIcon className='size-5 text-gray-400 flex-none absolute right-4 pointer-events-none' />
      </div>
    </div>
  )

  const components: ReactElement[] = []

  filters.forEach(filter => {
    const schema = filter.schema as OpenAPIV3_1.SchemaObject
    if (schema.enum) {
      const options = schema.enum.map(entry => ({
        key: entry,
        name: t(`filters.${entry.name}`)
      }))

      components.push(renderSelect(filter, options))
    } else if (schema.type === 'array') {
      if (filter.name === 'category') {
        components.push(<SelectInputApi definitionName='categories' filter={filter} key={filter.name} onChangeHandler={onChangeHandler} />)
      } else {
        components.push(renderText(filter))
      }
    } else if (schema.type === 'boolean') {
      const options = [
        {
          key: false,
          name: 'boolean.false'
        },
        {
          key: true,
          name: 'boolean.true'
        }
      ]

      components.push(renderSelect(filter, options))
    } else if (schema.type === 'string') {
      components.push(renderText(filter))
    } else {
      components.push(renderText(filter))
    }
  })

  return (
    <div className='flex items-center gap-4 w-auto'>
      {components}
    </div>
  )
}

export default ApiResourceListFilters
