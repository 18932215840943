import { OpenAPIV3_1 } from 'openapi-types'

import { MethodDefinition } from '@interfaces/api/definition/method-definition'
import { prefix } from '@services/api/definition'
import { ApiDefinition } from '@services/api/definition/api-definition'
import { Method } from '@services/api/definition/method'

export class Resource {
  apiDefinition: ApiDefinition
  title: string
  name: string
  url: string
  methods: Record<string, Method>

  children: Resource[]

  constructor (path: string, definition: OpenAPIV3_1.PathItemObject, apiDefinition: ApiDefinition) {
    const resourceURL = path.replace(prefix, '').split('/')
    const name = resourceURL[0]
    const title = resourceURL[0].split('-').map(word => {
      return word[0].toUpperCase() + word.substring(1)
    }).join(' ')
    const children: Resource[] = []

    this.apiDefinition = apiDefinition
    this.name = name
    this.title = title
    this.url = path
    this.methods = {}
    this.children = children
    this.appendMethods(definition, true)
  }

  appendMethods (methods, isAtRootPath = false) {
    for (const [method, methodDefinition] of Object.entries(methods)) {
      if (method !== 'parameters') {
        if (isAtRootPath && method === 'get') {
          this.methods.list = new Method(methodDefinition as MethodDefinition, this.apiDefinition)
        } else {
          this.methods[method] = new Method(methodDefinition as MethodDefinition, this.apiDefinition)
        }
      }
    }
  }
}
