import { HomeIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import {
  NavLink,
  useLocation
} from 'react-router-dom'

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import SectionTitle from '@components/section-title/section-title'
import { useNavigationLinks } from '@root/services/hooks/navigation-links'

const CategoryHome = () => {
  const { t: translateGeneral } = useTranslation('general', {
    keyPrefix: 'sidebar'
  })

  const { getCurrentPageSubLinks } = useNavigationLinks()
  const location = useLocation()

  const pathname = location.pathname.replace('/', '')

  const breadcrumbs = [
    {
      href: '',
      name: translateGeneral(pathname)
    }
  ]

  return (
    <Layout title={translateGeneral(pathname)}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <Guard errorPage={false}>
        <SectionTitle icon={HomeIcon} title={translateGeneral(pathname)} />

        <>
          {getCurrentPageSubLinks().map((sublink) => (
            !!sublink?.name && (
              <NavLink
                className={classNames(
                  'group flex items-center px-2 py-1 text-sm rounded-md',
                  'bg-white-800 text-black',
                  'text-black hover:bg-gray-200 hover:text-grey-100'
                )}
                key={sublink.name}
                to={sublink.href}
              >
                <div key={sublink?.name}>{translateGeneral(sublink?.name)}</div>
              </NavLink>
            )
          ))}
        </>
      </Guard>
    </Layout>
  )
}

export default CategoryHome
