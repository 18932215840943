import {
  ArrowsUpDownIcon,
  CheckIcon,
  DocumentDuplicateIcon,
  HandRaisedIcon,
  PencilSquareIcon,
  TrashIcon
} from '@heroicons/react/24/outline'
import { pluralize } from 'inflection'
import {
  useMemo,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'

import {
  ApiResourceListEntryActionsProps,
  TransitionAttribute
} from '@components/api-resource/api-resource-list-entry-actions/api-resource-list-entry-actions.interfaces'
import ContextualButton from '@components/buttons/contextual-button'
import TooltipButton from '@components/buttons/tooltip-button/tooltip-button'
import Modal from '@components/modals/modal'
import { ApiPlatformEntity } from '@interfaces/api'
import useApproveEntry from '@root/services/api/resources/approve-entry-query'
import useRejectEntry from '@root/services/api/resources/reject-entry-query'
import useCloneEntry from '@services/api/resources/clone-entry-query'
import useDeleteEntrySimple from '@services/api/resources/delete-entry-query-simple'
import { captureException } from '@services/exceptions/capture-exception'
import { useResourceActions } from '@services/hooks/resources'

const ApiResourceListEntryActions = <T extends ApiPlatformEntity>({
  definition,
  entry,
  identifierAttribute = 'uid',
  onReload,
  transitionAttribute = 'transition',
  withApproveOption,
  withCloneOption = false,
  withCopyOption,
  withDeleteOption = false,
  withEditOption,
  withMoveOption,
  withRejectOption
}: ApiResourceListEntryActionsProps<T>) => {
  const { t: translateActions } = useTranslation('apiResources', {
    keyPrefix: 'actions'
  })

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openRejectModal, setOpenRejectModal] = useState<boolean>(false)
  const [openApproveModal, setOpenApproveModal] = useState<boolean>(false)
  const [openCloneModal, setOpenCloneModal] = useState<boolean>(false)
  const { mutateAsync: deleteResourceEntry } = useDeleteEntrySimple()
  const { mutateAsync: rejectResourceEntry } = useRejectEntry()
  const { mutateAsync: approveResourceEntry } = useApproveEntry()
  const { mutateAsync: cloneResourceEntry } = useCloneEntry(`api/${definition.url}/clone`)

  const handleDeleteClick = (e) => {
    e.stopPropagation()
    setOpenDeleteModal(true)
  }

  const { canApplyTransition } = useResourceActions(entry)

  const canApprove = useMemo(() => {
    return withApproveOption && canApplyTransition
  }, [canApplyTransition, withApproveOption])

  const canReject = useMemo(() => {
    return withRejectOption && canApplyTransition
  }, [canApplyTransition, withRejectOption])

  const handleApproveClick = (e) => {
    e.stopPropagation()

    if (typeof withApproveOption === 'function') {
      withApproveOption?.(entry)
    } else {
      setOpenApproveModal(true)
    }
  }

  const handleRejectClick = (e) => {
    e.stopPropagation()

    if (typeof withRejectOption === 'function') {
      withRejectOption?.(entry)
    } else {
      setOpenRejectModal(true)
    }
  }

  const handleCloneClick = (e) => {
    e.stopPropagation()
    setOpenCloneModal(true)
  }

  const handleEditClick = (e) => {
    e.stopPropagation()
    withEditOption?.(entry)
  }

  const handleMoveClick = (e) => {
    e.stopPropagation()
    withMoveOption?.(entry)
  }

  const handleCancelClick = () => {
    setOpenDeleteModal(false)
    setOpenApproveModal(false)
    setOpenRejectModal(false)
    setOpenCloneModal(false)
  }

  const handleCopyClick = () => {
    withCopyOption?.(entry)
  }

  let path = `${definition.url}`

  const onDeleteHandler = () => {
    if (definition.name === 'experiences' && 'type' in entry) {
      path = pluralize(entry.type as string)
    }
    deleteResourceEntry({
      id: entry[identifierAttribute],
      path: `${path}/{uid}`
    }).then(() => {
      onReload()
      setOpenDeleteModal(false)
    }).catch(captureException)
  }

  const onApproveHandler = () => {
    if (definition.name === 'experiences' && 'type' in entry) {
      path = pluralize(entry.type as string)
    }
    const customTransitionValue = (transitionAttribute as TransitionAttribute)?.approve as string
    approveResourceEntry({
      complete: !customTransitionValue,
      id: entry[identifierAttribute],
      path: `${path}/{uid}/${customTransitionValue ?? transitionAttribute}`
    }).then(() => {
    }).then(() => {
      onReload()
      setOpenApproveModal(false)
    }).catch(captureException)
  }

  const onRejectHandler = () => {
    if (definition.name === 'experiences' && 'type' in entry) {
      path = pluralize(entry.type as string)
    }
    const customTransitionValue = (transitionAttribute as TransitionAttribute)?.reject as string
    rejectResourceEntry({
      complete: !customTransitionValue,
      id: entry[identifierAttribute],
      path: `${path}/{uid}/${customTransitionValue ?? transitionAttribute}`
    }).then(() => {
      onReload()
      setOpenRejectModal(false)
    }).catch(captureException)
  }

  const onCloneHandler = () => {
    cloneResourceEntry({
      original: entry['@id']
    }).then(() => {
      onReload()
      setOpenCloneModal(false)
    }).catch(captureException)
  }

  return (
    <div className='flex items-center gap-4'>
      {withCloneOption && (
        <>
          <button
            className='flex items-center gap-2 text-gray-700 fill-gray-700 group-hover:hover:fill-white py-1 px-2 group-hover:hover:bg-gray-800 group-hover:hover:text-white text-sm rounded-md cursor-pointer bg-gray-100 hover:bg-white hover:border-gray-900 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white'
            onClick={handleCloneClick}
          >
            <DocumentDuplicateIcon className='w-5 h-5 mx-auto' />
          </button>

          <Modal center open={openCloneModal} setOpen={setOpenCloneModal} title={translateActions('duplicateResource')}>
            <div className='flex flex-col items-center p-8'>
              <div className='text-center text-xl'>{translateActions('duplicateConfirmation')}</div>

              <div className='flex mt-6 gap-8'>
                <ContextualButton onClick={onCloneHandler}>
                  {translateActions('clone')}
                </ContextualButton>

                <ContextualButton onClick={handleCancelClick}>{translateActions('cancel')}</ContextualButton>
              </div>
            </div>
          </Modal>
        </>
      )}

      {withCopyOption && (
        <TooltipButton handleCopyClick={handleCopyClick} />
      )}

      {withEditOption && (
        <>
          <button
            className='flex items-center gap-2 text-gray-700 fill-gray-700 group-hover:hover:fill-white py-1 px-2 group-hover:hover:bg-gray-800 group-hover:hover:text-white text-sm rounded-md cursor-pointer bg-gray-100 hover:bg-white hover:border-gray-900 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white'
            onClick={handleEditClick}
          >
            <PencilSquareIcon className='w-5 h-5 mx-auto' />
          </button>
        </>
      )}

      {withMoveOption && (
        <>
          <button
            className='flex items-center gap-2 text-gray-700 fill-gray-700 group-hover:hover:fill-white py-1 px-2 group-hover:hover:bg-gray-800 group-hover:hover:text-white text-sm rounded-md cursor-pointer bg-gray-100 hover:bg-white hover:border-gray-900 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white'
            onClick={handleMoveClick}
          >
            <ArrowsUpDownIcon className='w-5 h-5 mx-auto' />
          </button>
        </>
      )}

      {withDeleteOption && (
        <>
          <button
            className='flex items-center text-gray-700 fill-gray-700 group-hover:hover:fill-white gap-2 py-1 px-2 group-hover:hover:bg-red-500 group-hover:hover:border-red-500 group-hover:hover:text-white text-sm rounded-md hover:text-red-700 bg-gray-100 cursor-pointer hover:bg-white hover:border-red-700 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white'
            onClick={handleDeleteClick}
          >
            <TrashIcon className='w-5 h-5 mx-auto' />
          </button>

          <Modal center open={openDeleteModal} setOpen={setOpenDeleteModal} title={translateActions('deleteResource')}>
            <div className='flex flex-col items-center p-8'>
              <div className='text-center text-xl'>{translateActions('deleteConfirmation')}</div>

              <div className='flex mt-6 gap-8'>
                <ContextualButton onClick={onDeleteHandler}
                  style='warning'
                >
                  {translateActions('delete')}
                </ContextualButton>

                <ContextualButton onClick={handleCancelClick}>{translateActions('cancel')}</ContextualButton>
              </div>
            </div>
          </Modal>
        </>
      )}

      {canApprove && (
        <>
          <button
            className='flex items-center text-gray-700 fill-gray-700 group-hover:hover:fill-white gap-2 py-1 px-2 group-hover:hover:bg-green-500 group-hover:hover:border-green-500 group-hover:hover:text-white text-sm rounded-md hover:text-red-700 bg-gray-100 cursor-pointer hover:bg-white hover:border-red-700 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white'
            onClick={handleApproveClick}
          >
            <CheckIcon className='w-5 h-5 mx-auto' />
          </button>

          <Modal center open={openApproveModal} setOpen={setOpenApproveModal} title={translateActions('approveResource')}>
            <div className='flex flex-col items-center p-8'>
              <div className='text-center text-xl'>{translateActions('approveConfirmation')}</div>

              <div className='flex mt-6 gap-8'>
                <ContextualButton onClick={onApproveHandler}
                  style='warning'
                >
                  {translateActions('approve')}
                </ContextualButton>

                <ContextualButton onClick={handleCancelClick}>{translateActions('cancel')}</ContextualButton>
              </div>
            </div>
          </Modal>
        </>
      )}

      {canReject && (
        <>
          <button
            className='flex items-center text-gray-700 fill-gray-700 group-hover:hover:fill-white gap-2 py-1 px-2 group-hover:hover:bg-orange-500 group-hover:hover:border-orange-500 group-hover:hover:text-white text-sm rounded-md hover:text-orange-700 bg-gray-100 cursor-pointer hover:bg-white hover:border-orange-700 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white'
            onClick={handleRejectClick}
          >
            <HandRaisedIcon className='w-5 h-5 mx-auto' />
          </button>

          <Modal center open={openRejectModal} setOpen={setOpenRejectModal} title={translateActions('rejectResource')}>
            <div className='flex flex-col items-center p-8'>
              <div className='text-center text-xl'>{translateActions('rejectConfirmation')}</div>

              <div className='flex mt-6 gap-8'>
                <ContextualButton onClick={onRejectHandler}
                  style='warning'
                >
                  {translateActions('reject')}
                </ContextualButton>

                <ContextualButton onClick={handleCancelClick}>{translateActions('cancel')}</ContextualButton>
              </div>
            </div>
          </Modal>
        </>
      )}
    </div>
  )
}

export default ApiResourceListEntryActions
