import { ClipboardIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import React, {
  useRef,
  useState
} from 'react'

import { TooltipButtonProps } from '@components/buttons/tooltip-button/tooltip-button.interfaces'

const TooltipButton = ({
  handleCopyClick,
  simple = false,
  size = 'size-5',
  text = 'Copier le code'
}: TooltipButtonProps) => {
  const [tooltip, setTooltip] = useState(text)

  const [showTooltip, setShowTooltip] = useState(false)
  const hideTooltipTimeout = useRef<NodeJS.Timeout | null>(null)

  const handleMouseEnter = () => {
    if (hideTooltipTimeout.current) {
      clearTimeout(hideTooltipTimeout.current)
    }
    setShowTooltip(true)
  }

  const handleMouseLeave = () => {
    hideTooltipTimeout.current = setTimeout(() => {
      setShowTooltip(false)
    }, tooltip === 'Copié !' ? 1000 : 100)
  }

  const onClick = () => {
    handleCopyClick()
    setTooltip('Copié !')
    setTimeout(() => {
      setTooltip(text)
    }, 1500)
  }

  return (
    <div className='relative flex items-center justify-center'>
      <button
        className={classNames(
          'relative flex items-center justify-center gap-2 fill-gray-700 py-1 px-2 text-sm rounded-md cursor-pointer group',
          {
            'text-gray-400': simple,
            'text-gray-700 bg-gray-100 border border-gray-100': !simple
          }
        )}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <ClipboardIcon className={`${size} mx-auto`} />

        {showTooltip && (
          <span className='absolute bottom-full mb-2'>
            <span className='relative z-10 p-2 text-xs leading-none text-white whitespace-nowrap bg-black shadow-lg rounded-md'>
              {tooltip}
            </span>

            <div className='w-3 h-3 absolute left-1/2 transform -translate-x-1/2 -ml-1.5 bottom-0 rotate-45 bg-black' />
          </span>
        )}
      </button>
    </div>
  )
}

export default TooltipButton
