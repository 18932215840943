import packageJson from '@base/package.json'
import {
  ArrowLeftEndOnRectangleIcon,
  XCircleIcon
} from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import Guard from '@components/guard/guard'
import { SidebarProps } from '@components/sidebar/sidebar.interfaces'
import { useNavigationLinks } from '@root/services/hooks/navigation-links'
import { useLogout } from '@services/hooks/auth-token'

const Sidebar = ({
  open,
  setOpen
}: SidebarProps) => {
  const { logout } = useLogout()
  const { t } = useTranslation('general', {
    keyPrefix: 'sidebar'
  })
  const isProd = process.env.REACT_APP_DEPLOYMENT_TIER === 'production'

  const { sideBarLinks } = useNavigationLinks()

  const closeSidebar = () => {
    setOpen(false)
  }

  return (
    <div className={classNames(
      'py-6 pb-0 transition h-[100vh] tracking-wide text-lg font-medium',
      'fixed z-20 top-0 left-0 min-w-max sm:min-w-min w-3/5',
      'before:fixed before:-z-10 before:inset-0 before:bg-gray-900 before:opacity-50',
      'md:sticky md:w-56 md:before:hidden md:opacity-100 md:pointer-events-auto md:translate-x-0',
      {
        'bg-gray-900': isProd,
        'bg-slate-800': !isProd,
        'opacity-0 -translate-x-1/3 pointer-events-none': !open,
        'opacity-100 translate-x-0': open
      }
    )}
    >
      <div className='md:sticky h-full flex flex-col overflow-y-auto scrollbar-hide top-6 text-sm'>
        <div className='flex items-center flex-shrink-0 px-4'>
          <NavLink to={'/'}>
            <img
              alt='logo'
              className='h-auto w-10'
              src='/assets/tosnoops-logo.png'
            />
          </NavLink>

          <button className='p-2 ml-auto md:hidden' onClick={closeSidebar}>
            <XCircleIcon className='w-8 h-8 text-white' />
          </button>
        </div>

        <nav className='px-2 mt-2 md:mt-6 flex-1'>
          {sideBarLinks.map((item) => (
            <Guard errorPage={false} key={item.name}>
              <>
                {item.title && (
                  <div className='flex items-center mt-4 mb-2'>
                    {item.icon && (
                      <item.icon
                        aria-hidden='true'
                        className={classNames(
                          'text-white',
                          'flex-shrink-0 size-4'
                        )}
                      />
                    )}

                    <div className='text-gray-200 px-2'>{t(item.title)}</div>
                  </div>
                )}

                <NavLink
                  className={({ isActive }) => classNames(
                    'group flex items-center px-2 py-1 text-md rounded-md',
                    {
                      'bg-gray-800 text-white': isActive,
                      'text-gray-400 hover:bg-gray-800 hover:text-white': isProd && !isActive,
                      'text-white hover:bg-gray-900 hover:text-white': !isProd && !isActive
                    }
                  )}
                  to={item.href}
                >
                  {!item.title && item.icon && (
                    <item.icon
                      aria-hidden='true'
                      className={classNames(
                        'text-white mr-2',
                        'flex-shrink-0 size-4'
                      )}
                    />
                  )}

                  {t(item.name)}
                </NavLink>

                {item.sublinks && (
                  <div className='ml-4 mt-1'>
                    {item.sublinks.map((sublink) => (
                      <NavLink
                        className={({ isActive }) => classNames(
                          'group flex items-center px-2 py-1 text-sm rounded-md',
                          {
                            'bg-gray-800 text-white': isActive,
                            'text-gray-400 hover:bg-gray-800 hover:text-white': isProd && !isActive,
                            'text-white hover:bg-gray-900 hover:text-white': !isProd && !isActive
                          }
                        )}
                        key={sublink.name}
                        to={sublink.href}
                      >
                        {t(sublink.name)}
                      </NavLink>
                    ))}
                  </div>
                )}
              </>
            </Guard>
          ))}
        </nav>

        <div className='flex flex-col py-4'>
          <button className='group flex items-center px-2 py-2 text-md rounded-md text-gray-200 mt-4 md:mt-8 hover:bg-gray-800 hover:text-white w-full' onClick={logout}>
            <ArrowLeftEndOnRectangleIcon
              aria-hidden='true'
              className='gray-400 group-hover:gray-300 mr-2 flex-shrink-0 size-4'
            />

            {t('logout')}
          </button>

          <NavLink className='text-gray-300 text-xs px-2 mt-4' to='/changelog'>
            {`${t('changelog')} - ${packageJson.version}`}
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
