import React, { ReactElement } from 'react'

import { GroupedFieldsInterfaces } from '@components/api-resource/api-resource-form/form-fields/grouped-fields'
import { fieldIsHidden } from '@components/api-resource/api-resource-form/form-utils/fields'
import { ApiPlatformEntity } from '@interfaces/api'

const GroupedFields = <T extends ApiPlatformEntity>({
  data,
  definition,
  fieldsToDisplay,
  fieldsToHide,
  requestFields
}: GroupedFieldsInterfaces<T>) => {
  // const { t } = useTranslation('apiResources')

  const fields: ReactElement[] = []

  // Créez un objet pour associer les noms de champs aux composants correspondants
  const fieldComponents = {}

  requestFields.forEach((field, index) => {
    if ('type' in field.properties) {
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      if (fieldIsHidden(field, fieldsToDisplay, fieldsToHide)) {
        // return
      }

      /* if (field.name === 'duration') {
        const unitField = 'durationUnit'
        const unitOptions = Object.values(DurationUnit).map(unit => ({
          key: unit,
          name: t(`labels.durationUnit.values.${unit}`)
        }))

        const unitcomponent = (
          <FormSelectField defaultValue={data?.[unitField] || DurationUnit.minute} groupedField key={unitField} name={unitField} options={unitOptions} />
        )

        fieldComponents[field.name] = (
          <div className='flex' key={field.name}>
            <FormNumberField label={t([`labels.${definition.name}.${field.name}`, `labels.${field.name}`])} name={field.name} required={field.required ?? false} unitComponent={unitcomponent} zeroAllowed />
          </div>
        )
      } */
    }
  })

  // Parcourez fieldsToDisplay si défini, sinon ajoutez les champs dans l'ordre d'origine
  if (fieldsToDisplay) {
    fieldsToDisplay.forEach((fieldName) => {
      if (fieldComponents[fieldName]) {
        fields.push(fieldComponents[fieldName])
      }
    })
  } else {
    Object.keys(fieldComponents).forEach((fieldName) => {
      fields.push(fieldComponents[fieldName])
    })
  }

  return (
    <>
      {fields}
    </>
  )
}

export default GroupedFields
