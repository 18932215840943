import { ListBulletIcon } from '@heroicons/react/24/outline'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApiResource from '@components/api-resource'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import SectionTitle from '@components/section-title/section-title'
import { Category } from '@interfaces/api/category'
import ApiResourceMutateWrapperModal from '@root/components/api-resource-mutate-wrapper-modal'
import useApiResource from '@services/api/definition'
import { captureException } from '@services/exceptions/capture-exception'

const CategoriesList = () => {
  const { t: translateResource } = useTranslation('apiResources', {
    keyPrefix: 'categories'
  })

  const breadcrumbs = [
    {
      href: '',
      name: translateResource('title')
    }
  ]

  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [openMove, setOpenMove] = useState(false)
  const [category, setCategory] = useState<Category>()

  const queryClient = useQueryClient()
  const definition = useApiResource('categories/{uid}')
  const moveDefinition = useApiResource('categories/{uid}/move')
  const collectionDefinition = useApiResource('categories')
  const moveCollectionDefinition = useApiResource('categories-tree')

  const onReload = () => {
    queryClient.refetchQueries({
      queryKey: ['categories']
    }).catch(captureException)
  }

  const onCreateClick = () => {
    setOpen(true)
  }

  const onEditClick = (entry) => {
    setCategory(entry)
    setOpenEdit(true)
  }

  const onMoveClick = (entry) => {
    setCategory(entry)
    setOpenMove(true)
  }

  const onEdit = () => {
    onReload()
    setOpenEdit(false)
    setOpen(false)
  }

  return (
    <Layout description={translateResource('description')} title={translateResource('title')}>
      <Guard>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle icon={ListBulletIcon} title={translateResource('title')} />

        <ApiResource
          customFieldsToDisplay={['name', 'leaf', 'featured', 'icon', 'level', 'createdAt', 'updatedAt']}
          name={'categories'}
          withCreateOption={onCreateClick}
          withDeleteOption
          withEditOption={onEditClick}
          withMoveOption={onMoveClick}
        />

        <ApiResourceMutateWrapperModal
          createDefinition={collectionDefinition}
          createTitle={translateResource('create')}
          createVisible={open}
          editDefinition={definition}
          editTitle={translateResource('edit')}
          editVisible={openEdit}
          moveCollectionDefinition={moveCollectionDefinition}
          moveDefinition={moveDefinition}
          moveTitle={translateResource('move')}
          moveVisible={openMove}
          onSave={onEdit}
          resource={category}
          setCreateVisible={setOpen}
          setEditVisible={setOpenEdit}
          setMoveVisible={setOpenMove}
        />
      </Guard>
    </Layout>
  )
}

export default CategoriesList
