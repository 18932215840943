/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Field } from '@interfaces/api/definition/field'

export const IMAGE_FIELDS = ['cover', 'photo', 'image', 'picture', 'logo', 'customCover', 'icon']
export const IMAGE_MULTI_FIELDS = ['images']
export const VIDEO_FIELDS = ['video']
export const FILE_FIELDS = ['file']
export const MEDIA_FIELDS = [...IMAGE_FIELDS, ...VIDEO_FIELDS, ...IMAGE_MULTI_FIELDS, ...FILE_FIELDS]

export const fieldIsHidden = (field: Field, fieldsToDisplay?: string[], fieldsToHide?: string[]) => {
  return (fieldsToDisplay?.length && fieldsToDisplay?.length > 0 && !fieldsToDisplay?.includes(field.name)) || (fieldsToHide?.includes(field.name))
}

export const hasMediaOrImageFields = (requestFields: Field[]): boolean => {
  const IMAGE_FIELDS = ['cover', 'photo', 'image', 'picture', 'logo', 'customCover', 'icon']
  const IMAGE_MULTI_FIELDS = ['images']
  const VIDEO_FIELDS = ['video']
  const FILE_FIELDS = ['file']

  return requestFields.some((field) =>
    ('type' in field.properties &&
      (IMAGE_FIELDS.includes(field.name) ||
        IMAGE_MULTI_FIELDS.includes(field.name) ||
        VIDEO_FIELDS.includes(field.name.toLowerCase()) ||
        FILE_FIELDS.includes(field.name)))
  )
}
