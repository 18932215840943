import {
  useMutation,
  UseMutationResult
} from '@tanstack/react-query'

import useAxios from '@services/api/axios'

const useCloneEntry = (path: string): UseMutationResult => {
  const { post } = useAxios()

  return useMutation({
    mutationFn: async (data) => await post<never, never>(path, data as never).then((body) => body)
  })
}

export default useCloneEntry
