import { ApiPlatformEntity } from '@interfaces/api'

export enum KycDocumentMangoPayRefusedReasonType {
  DOCUMENT_DO_NOT_MATCH_ACCOUNT_DATA = 'DOCUMENT_DO_NOT_MATCH_ACCOUNT_DATA',
  TRIGGER_INTERPOL = 'TRIGGER_INTERPOL',
  TRIGGER_PEPS = 'TRIGGER_PEPS',
  TRIGGER_SANCTION_LISTS = 'TRIGGER_SANCTION_LISTS'
}

export enum KycDocumentAdminRefusedReasonType {
  DOCUMENT_DO_NOT_MATCH_USER_DATA = 'DOCUMENT_DO_NOT_MATCH_USER_DATA',
  DOCUMENT_FALSIFIED = 'DOCUMENT_FALSIFIED',
  DOCUMENT_HAS_EXPIRED = 'DOCUMENT_HAS_EXPIRED',
  DOCUMENT_INCOMPLETE = 'DOCUMENT_INCOMPLETE',
  DOCUMENT_MISSING = 'DOCUMENT_MISSING',
  DOCUMENT_NOT_ACCEPTED = 'DOCUMENT_NOT_ACCEPTED',
  DOCUMENT_UNREADABLE = 'DOCUMENT_UNREADABLE',
  SPECIFIC_CASE = 'SPECIFIC_CASE',
  UNDERAGE_PERSON = 'UNDERAGE_PERSON'
}

export enum DocumentType {
  identityProof = 'IDENTITY_PROOF'
}

export enum KycDocumentStatus {
  created = 'CREATED',
  outOfDate = 'OUT_OF_DATE',
  refused = 'REFUSED',
  validated = 'VALIDATED',
  validationAsked = 'VALIDATION_ASKED'
}

export interface DocumentFile extends ApiPlatformEntity {
  mime: string
  originalName: string
  size: number
  url: string
}

export interface KycDocument extends Omit<ApiPlatformEntity, '@context'> {
  createdAt: string
  files?: string[]
  flags: string[]
  mangopayId: string
  name?: string
  preprocessed: boolean
  processedAt: string
  refusedReasonMessage: string
  refusedReasonType: KycDocumentMangoPayRefusedReasonType | KycDocumentAdminRefusedReasonType
  status: KycDocumentStatus
  submitted: boolean
  type: DocumentType
  uid: string
  updatedAt: string
}
