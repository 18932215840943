import { SkeletonLoaderTableProps } from '@components/skeleton-loader/skeleton-loader-interfaces'

const SkeletonLoaderTable = ({ headers }: SkeletonLoaderTableProps) => (
  <>
    {Array.from({
      length: 5
    }).map((_, index) => (
      <tr key={index}>
        {headers.map(header => (
          <td className='p-6' key={header}>
            <div className='max-w-sm animate-pulse' role='status'>
              <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-500' />
            </div>
          </td>
        ))}
      </tr>
    ))}
  </>
)

export default SkeletonLoaderTable
