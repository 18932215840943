import slugify from 'slugify'

import { ApiPlatformEntity } from '@interfaces/api'
import { Field } from '@interfaces/api/definition/field'
import { isPropertyType } from '@services/tools/api-resources/property-types'

function getDefaultValue (field: Field) {
  let defaultValue: (string | number | boolean | null | []) = ''
  if ('type' in field.properties) {
    if (isPropertyType(field, 'string')) {
      defaultValue = ''
    }
    if (isPropertyType(field, 'string') && field.name === 'status') {
      defaultValue = 'PUBLISHED'
    }
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    if (isPropertyType(field, 'integer') || isPropertyType(field, 'number')) {
      defaultValue = 1
    }
    if (isPropertyType(field, 'integer') && isPropertyType(field, 'null')) {
      defaultValue = null
    }
    if (isPropertyType(field, 'boolean')) {
      defaultValue = false
    }
    if (isPropertyType(field, 'array')) {
      defaultValue = []
    }
    if (isPropertyType(field, 'object')) {
      defaultValue = ''
    }
  }

  return defaultValue
}

export const getInitialValues = <T extends ApiPlatformEntity>(
  fields: Field[],
  existingValues: T | never[],
  extraFields?: Field[]
) => {
  const initializeField = (field: Field) => {
    let value = getDefaultValue(field)

    if (field.name in existingValues && existingValues[field.name] !== null) {
      value = existingValues[field.name]
    }

    if (field.name in existingValues && Array.isArray(existingValues[field.name]) && field.name === 'tags') {
      value = existingValues[field.name].map((item) => item['@id'])
    }

    if (field.name === 'price' && existingValues[field.name]) {
      value = existingValues[field.name] / 100
    }

    return [field.name, value]
  }

  const initialValues = fields.map(initializeField)

  if (extraFields) {
    extraFields.forEach(field => {
      initialValues.push(initializeField(field))
    })
  }

  const newValues = Object.fromEntries(initialValues)

  // Handle empty slug with existing name
  if ('slug' in newValues && 'name' in newValues && newValues.slug === '' && newValues.name !== '') {
    newValues.slug = slugify(newValues.name).toLowerCase()
  }

  return newValues
}
