import {
  HomeIcon,
  NewspaperIcon,
  UserIcon
} from '@heroicons/react/24/outline'
import { useLocation } from 'react-router'

import { NavigationItem } from '@components/sidebar/sidebar.interfaces'

const sideBarLinks: NavigationItem[] = [
  {
    href: '/',
    icon: HomeIcon,
    name: 'home'
  },
  {
    href: '/home-users',
    icon: UserIcon,
    name: 'home-users',
    sublinks: [{
      href: '/users',
      name: 'users'
    },
    {
      href: '/kyc-documents',
      name: 'kycDocuments'
    }]
  },
  {
    href: '/home-adverts',
    icon: NewspaperIcon,
    name: 'home-adverts',
    sublinks: [{
      href: '/adverts',
      name: 'adverts'
    }, {
      href: '/brands',
      name: 'brands'
    },
    {
      href: '/categories',
      name: 'categories'
    },
    {
      href: '/item-conditions',
      name: 'itemConditions'
    }
    ]
  }
]

export const useNavigationLinks = () => {
  const location = useLocation()

  const getCurrentPageSubLinks = () => {
    return sideBarLinks.find((link) => link.href === location.pathname)?.sublinks ?? []
  }

  return {
    getCurrentPageSubLinks,
    sideBarLinks
  }
}
